<template>
    <div>
        <!-- Search field -->
        <div class="field has-addons has-addons-centered mb-1 ">
            <p class="control" style="width:60%;">
                <input v-if="search_mode == 'all'" type="text" v-model="search" class="input" :placeholder="$t('search')" />
                <input v-else-if="search_mode == 'tetris'" type="text" v-model="$parent.tetris_search_text" class="input" :placeholder="$t('search')" />
            </p>
            <p class="control">
                <label class="button is-centered"
                    :class="{
                        'has-background-info': search_mode == 'all',
                        'has-background-info-light has-text-dark': search_mode != 'all' }">
                <input type="radio" id="one" value="all"
                    v-model="search_mode"
                    @change="searchProjects"/>
                <label for="one" class="ml-2">EJ</label>
                </label>
            </p>
            <p class="control">
            <label class="button is-centered"
                :class="{
                    'has-background-info': search_mode == 'tetris',
                    'has-background-info-light has-text-dark': search_mode != 'tetris'
                }"
                >
                <input type="radio" id="two" value="tetris"
                v-model="search_mode"
                @change="searchProjects"
                />
                <label for="two" class="ml-2">Tetris</label>
            </label>
            </p>
        </div>
        <!-- Search results -->
        <div v-if="search != '' && search_mode == 'all'" class="search-result-wrap custom_scrollbar-1 ">
            <div class="is-flex mb-1 ml-1 has-text-dark">
                <span>
                    {{$t("search_results") }}
                </span>
                <button @click.prevent="search = ''" class="delete ml-auto"></button>
            </div>
            <ul v-for="year in years" :key="year">
                <li class="p-1 pl-2 mb-1 year_search_result">
                    {{ year }}
                </li>
                <li class="is-clearfix mb-1 proj-search-result" v-for="project in year_projects(year)" :key="project.Id">

                    <div @click="setCurrentEvent(project)" class="columns is-clickable m-0">
                        <div class="column is-7 p-1">
                            <span v-if="project.ProjectState == 'Confirmed'"
                                class="circle has-background-success" >
                                &nbsp;
                            </span>
                            <span class="circle has-background-danger has-text-dark" v-else></span>
                            <span class="ml-1">
                                {{ project.Caption }}
                            </span>
                        </div>
                        <div class="column is-flex is-justify-content-space-between p-1">
                            <span>
                                [{{ project.StartDate | customdate }}
                            </span>
                            <span> ... </span>
                            <span>
                                {{ project.EndDate | customdate }}]
                            </span>
                        </div>
                    </div>

                    <!-- <a href="" @click.prevent="setCurrentEvent(project)" class="">
                        <span class="columns">
                            <span v-if="project.ProjectState == 'Confirmed'"
                                class="circle has-background-success" >
                                &nbsp;
                            </span>
                            <span class="circle has-background-danger has-text-dark" v-else></span>
                            {{ project.Caption }}
                        </span>
                        <span class="is-pulled-right">
                            [{{ project.StartDate | customdate }}...{{ project.EndDate | customdate }}]
                        </span>
                    </a> -->
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search_mode: "all", 
            search: "",
        }
    },
    computed: {
        years() {
            var years = []
            this.$parent.$parent.events.forEach((e) => {
            if (!years.includes(new Date(e.StartDate).getFullYear()))
                years.push(new Date(e.StartDate).getFullYear())
            if (!years.includes(new Date(e.EndDate).getFullYear()))
                years.push(new Date(e.EndDate).getFullYear())
            })
            return years.sort().reverse()
        },
        events() {
            return this.$parent.$parent.events.filter((e) =>
                JSON.stringify(e).toLowerCase().includes(this.search.toLowerCase())
            )
        },
    },
    watch: {
        search(value) {
            if(this.search_mode == 'all')
                this.$parent.$parent.getProjects(value, this.search_mode)
        }
    },
    methods: {
        setCurrentEvent(event) {
            let routeData = this.$router.resolve({
                path: "/delivery-request/" + event.Id,
                params: {id: "sciwomeData"}
            })
            window.open(routeData.href, "_blank")
        },
        searchProjects() {
          this.$parent.$parent.getProjects(this.search, this.search_mode)
        },
        year_projects(y) {
            var prv = []
            this.events.forEach((p) => {
                if (
                new Date(p.StartDate).getFullYear() == y ||
                new Date(p.EndDate).getFullYear() == y ||
                (y > new Date(p.StartDate).getFullYear() && new Date(p.EndDate).getFullYear() > y)
                )
                prv.push(p)
            })
            return prv
        },
    },

}
</script>

<style>
    .search-result-wrap{
        position: absolute;
        background: #fefde3  !important;
        z-index: 999;
        width: 100%;
        border: solid 1px #a9a9a9;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 0px 0px 3px 2px #c5c5c5;
        max-height:80vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    /* .search-result-wrap::-webkit-scrollbar{
        width:6px;
        height: 20cm;
        background:rgba(255, 166, 0, 0);
    }
    .search-result-wrap::-webkit-scrollbar-thumb{
        background: #3e8ed0;
        border-radius:10px;
    } */
    .year_search_result{
        color: #000;
        border-bottom: solid 1px #dedede;
        background: #eccd94;
    }
    .proj-search-result:hover{
        background: #fdfdf4;
    }
    .proj-search-result *{
        color:#000 !important;
    }
</style>
